<template>
  <div id="help_area">
    <div id="help_container">
        <div id="help_right_panel" class="embed-responsive embed-responsive-1by1">
          <iframe class="embed-responsive-item" src="/help/index.html"></iframe>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  data(){
    return{
      debug: '',
    }
  },
  computed: {

  },
  created: function() {

  },
  mounted: function() {

  },
  methods: {

  } //FIN METODOS
}
</script>

<style>
#help_area {
  position: relative;
  width: 100%;
  height: 100%;
  /*background: green;*/
}

#help_container {
  position: absolute;
  overflow: hidden;
  /* background: blueviolet;*/
  height: calc(100vh - 100px);
  width: 100%;
  /*border: 1px solid #c9c4ca;*/
 }  

 #help_right_panel {
  position: relative;
  overflow: scroll;
  float: right;
  /*background: rgb(236, 188, 28);*/
  height: 100%;
  /*width: 100%;*/
  /* right: 0px; */
  border: 2px solid #c9c4ca;
 }
</style>
